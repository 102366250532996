import {
  createPathologyOrder,
  getPathologyPanelById,
  getPathologyPanelsList,
  PathologyOrder,
  PathologyPanel,
  getPathologyOrderDetails,
  initiatePathologyPayment,
  PathologyPaymentReceipt,
  getPathologyPaymentReceipt,
  PathologyPaymentStatus,
} from "@/services/core-api-adapter";
import { assign, fromPromise, setup } from "xstate";

const MAX_POLLING_TIME_IN_SECONDS =
  Number(import.meta.env.VITE_APP_PATHOLOGY_MAX_POLLING_TIME) || 180;
const POLLING_INTERVAL_IN_MILLISECONDS =
  Number(import.meta.env.VITE_APP_PATHOLOGY_POLLING_INTERVAL) || 5000;

interface Context {
  fetchOrderDetailsResponse: PathologyOrder | null;
  createOrderResponse: PathologyOrder | null;
  initiatePaymentResponse: any;
  bloodPanelListResponse: PathologyPanel[];
  paymentReceiptResponse: PathologyPaymentReceipt | null;
  bloodPanelDetailsResponse: any;
  selectedBloodPanel: PathologyPanel | null;
  panelIdQueryParam: string | null;
  orderIdQueryParam: string | null;
  paymentStartTime: Date | null;
}

const initialContextValues: Context = {
  fetchOrderDetailsResponse: null,
  createOrderResponse: null,
  bloodPanelListResponse: [],
  initiatePaymentResponse: null,
  paymentReceiptResponse: null,
  bloodPanelDetailsResponse: {},
  selectedBloodPanel: null,
  panelIdQueryParam: null,
  orderIdQueryParam: null,
  paymentStartTime: null,
};

export const eventNames = {
  BACK_BUTTON_CLICKED: "BACK_BUTTON_CLICKED",
  BUY_NOW_BUTTON_CLICKED: "BUY_NOW_BUTTON_CLICKED",
  CONTINUE_BUTTON_CLICKED: "CONTINUE_BUTTON_CLICKED",
  USER_REDIRECTED: "USER_REDIRECTED",
  BLOOD_PANEL_SELECTED: "BLOOD_PANEL_SELECTED",
  GO_HOME_BUTTON_CLICKED: "GO_HOME_BUTTON_CLICKED",
  VIEW_RECEIPT_BUTTON_CLICKED: "VIEW_RECEIPT_BUTTON_CLICKED",
  VIEW_RESULTS_BUTTON_CLICKED: "VIEW_RESULTS_BUTTON_CLICKED",
  HOW_TO_PREPARE_BUTTON_CLICKED: "HOW_TO_PREPARE_BUTTON_CLICKED",
};

export const pathologyFlowStateMachine = setup({
  types: {
    context: {} as Context,
  },
  actions: {
    addOrderDetailsToContext: function () {
      return {};
    },

    addBloodPanelListResponseToContext: assign(({ event }) => {
      return {
        bloodPanelListResponse: event.output,
      };
    }),

    addSelectedBloodPanelToContext: assign(({ event }) => {
      return {
        selectedBloodPanel: event.output,
      };
    }),

    addCreateOrderResponseToContext: assign(({ event }) => {
      return {
        createOrderResponse: event.output,
      };
    }),

    addInitiatePaymentResponseToContext: assign(({ event }) => {
      return {
        initiatePaymentResponse: event.output,
      };
    }),

    addPaymentStartTimeToContext: assign(() => {
      return {
        paymentStartTime: new Date(),
      };
    }),

    addOrderDetailsResponseToContext: assign(({ event }) => {
      return {
        fetchOrderDetailsResponse: event.output,
      };
    }),

    addPaymentReceiptResponseToContext: assign(({ event }) => {
      return {
        paymentReceiptResponse: event.output,
      };
    }),
  },
  actors: {
    getBloodPanelById: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        const panelId =
          input?.context?.panelIdQueryParam ||
          input?.context.selectedBloodPanel?.panelId;
        return getPathologyPanelById(panelId || "");
      }
    ),
    getBloodPanelList: fromPromise(async () => {
      return getPathologyPanelsList();
    }),
    createOrder: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        return createPathologyOrder(
          input?.context?.selectedBloodPanel?.panelId || ""
        );
      }
    ),
    initiatePayment: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        const orderId = input?.context?.createOrderResponse?.orderId || "";
        const amount = input?.context?.selectedBloodPanel?.amountTotal || 0;

        return initiatePathologyPayment(orderId, amount);
      }
    ),
    fetchOrderDetails: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        return getPathologyOrderDetails(
          input?.context?.orderIdQueryParam ||
            input?.context?.createOrderResponse?.orderId ||
            ""
        );
      }
    ),
    fetchPaymentReceipt: fromPromise(
      async ({ input }: { input: { context: Context } }) => {
        return getPathologyPaymentReceipt(
          input?.context?.orderIdQueryParam ||
            input?.context?.createOrderResponse?.orderId ||
            ""
        );
      }
    ),
  },
  guards: {
    hasBloodPanelIdInQueryParams: function ({ context }) {
      return context.panelIdQueryParam !== null;
    },
    hasOrderIdInQueryParams: function ({ context }) {
      return context.orderIdQueryParam !== null;
    },
    paymentHasFailed: function () {
      return true;
    },
    hasOrderNumber: function () {
      return true;
    },
    hasNoOrderNumber: function () {
      return true;
    },
    timeoutExceeded: function ({ context }) {
      const currentDate = new Date();
      const paymentStartTime = context.paymentStartTime;

      if (paymentStartTime) {
        return (
          Number(currentDate.getTime() - paymentStartTime.getTime()) / 1000 >
          MAX_POLLING_TIME_IN_SECONDS
        );
      }

      return false;
    },
    userHasSuccessfulPayment: function ({ context }) {
      const isPaymentSuccessful = Boolean(
        context.paymentReceiptResponse?.paymentSuccess
      );

      return isPaymentSuccessful;
    },
    userHasUnsuccessfulPayment: function ({ context }) {
      return (
        context.paymentReceiptResponse?.status ===
        PathologyPaymentStatus.PAYMENT_FAILED
      );
    },
  },
  schemas: {},
  delays: {
    POLLING_INTERVAL: POLLING_INTERVAL_IN_MILLISECONDS,
  },
}).createMachine({
  context: initialContextValues,
  id: "pathologyFlow",
  initial: "showingBloodPanelListOrShowingBloodPanelDetails",
  states: {
    showingBloodPanelListOrShowingBloodPanelDetails: {
      entry: assign(({ event }) => {
        const entryContext = { ...initialContextValues };
        const { panelId = null, orderId = null } = event.input?.context
          ? event.input.context
          : {};
        return {
          ...entryContext,
          panelIdQueryParam: panelId,
          orderIdQueryParam: orderId,
        };
      }),
      always: [
        {
          target: "fetchingBloodPanelDetails",
          guard: {
            type: "hasBloodPanelIdInQueryParams",
          },
        },
        {
          target: "fetchingPaymentReceipt",
          guard: {
            type: "hasOrderIdInQueryParams",
          },
        },
        {
          target: "fetchingBloodPanelList",
        },
      ],
    },
    fetchingBloodPanelDetails: {
      invoke: {
        input: ({ context }) => ({ context }),
        onDone: {
          target: "showingBloodPanelDetails",
          actions: {
            type: "addSelectedBloodPanelToContext",
          },
        },
        onError: {
          target: "error",
        },
        src: "getBloodPanelById",
      },
    },
    fetchingBloodPanelList: {
      invoke: {
        input: {},
        onDone: {
          target: "showingBloodPanelList",
          actions: {
            type: "addBloodPanelListResponseToContext",
          },
        },
        onError: {
          target: "error",
        },
        src: "getBloodPanelList",
      },
    },
    showingBloodPanelDetails: {
      on: {
        BACK_BUTTON_CLICKED: [
          {
            target: "exit",
            guard: {
              type: "hasBloodPanelIdInQueryParams",
            },
          },
          {
            target: "showingBloodPanelList",
          },
        ],
        BUY_NOW_BUTTON_CLICKED: {
          target: "showingPrompt",
        },
      },
    },
    error: {
      on: {
        GO_HOME_BUTTON_CLICKED: {
          target: "exit",
        },
      },
    },
    showingBloodPanelList: {
      on: {
        BACK_BUTTON_CLICKED: {
          target: "exit",
        },
        USER_REDIRECTED: {
          target: "exit",
        },
        BLOOD_PANEL_SELECTED: {
          target: "fetchingBloodPanelDetails",
          actions: { type: "addSelectedBloodPanelToContext" },
        },
      },
    },
    exit: {
      type: "final",
    },
    showingPrompt: {
      on: {
        BACK_BUTTON_CLICKED: {
          target: "showingBloodPanelDetails",
        },
        CONTINUE_BUTTON_CLICKED: {
          target: "creatingOrder",
        },
      },
    },
    creatingOrder: {
      invoke: {
        input: ({ context }) => ({ context }),
        onDone: {
          target: "initiatingPayment",
          actions: {
            type: "addCreateOrderResponseToContext",
          },
        },
        onError: {
          target: "error",
        },
        src: "createOrder",
      },
    },
    initiatingPayment: {
      invoke: {
        input: ({ context }) => ({ context }),
        onDone: {
          target: "breakoutScreen",
          actions: {
            type: "addInitiatePaymentResponseToContext",
          },
        },
        onError: {
          target: "error",
        },
        src: "initiatePayment",
      },
    },
    breakoutScreen: {
      on: {
        USER_REDIRECTED: {
          target: "waitingForOrderOutcome",
          actions: {
            type: "addPaymentStartTimeToContext",
          },
        },
        BACK_BUTTON_CLICKED: {
          target: "showingPrompt",
        },
      },
    },
    waitingForOrderOutcome: {
      after: {
        POLLING_INTERVAL: {
          target: "fetchingPaymentReceipt",
        },
      },
    },
    fetchingPaymentReceipt: {
      invoke: {
        id: "fetchPaymentReceipt",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "processingPaymentOutcome",
          actions: {
            type: "addPaymentReceiptResponseToContext",
          },
        },
        onError: {
          target: "error",
        },
        src: "fetchPaymentReceipt",
      },
    },
    processingPaymentOutcome: {
      always: [
        {
          target: "exit",
          guard: {
            type: "timeoutExceeded",
          },
        },
        {
          target: "fetchingOrderDetails",
          guard: {
            type: "userHasSuccessfulPayment",
          },
        },
        {
          target: "paymentFailed",
          guard: {
            type: "userHasUnsuccessfulPayment",
          },
        },
        {
          target: "waitingForOrderOutcome",
        },
      ],
    },
    fetchingOrderDetails: {
      invoke: {
        id: "fetchOrderDetails",
        input: ({ context }) => ({ context }),
        onDone: {
          target: "showingOrderSummary",
          actions: {
            type: "addOrderDetailsResponseToContext",
          },
        },
        onError: {
          target: "error",
        },
        src: "fetchOrderDetails",
      },
    },
    paymentFailed: {
      on: {
        GO_HOME_BUTTON_CLICKED: {
          target: "exit",
        },
      },
    },
    showingOrderSummary: {
      on: {
        VIEW_RECEIPT_BUTTON_CLICKED: {
          target: "showingPaymentReceipt",
        },
        VIEW_RESULTS_BUTTON_CLICKED: {
          target: "showingWhatsappBreakout",
        },
        HOW_TO_PREPARE_BUTTON_CLICKED: {
          target: "showingHowToPreparePrompt",
        },
        BACK_BUTTON_CLICKED: [
          {
            target: "exitToMyPurchases",
            guard: {
              type: "hasOrderIdInQueryParams",
            },
          },
          {
            target: "exit",
          },
        ],
      },
    },
    exitToMyPurchases: {
      type: "final",
    },
    showingWhatsappBreakout: {
      on: {
        BACK_BUTTON_CLICKED: {
          target: "showingOrderSummary",
        },
      },
    },
    showingPaymentReceipt: {
      on: {
        BACK_BUTTON_CLICKED: {
          target: "showingOrderSummary",
        },
      },
    },
    showingHowToPreparePrompt: {
      on: {
        BACK_BUTTON_CLICKED: {
          target: "showingOrderSummary",
        },
      },
    },
  },
});
